import { defineStore } from 'pinia';
import { User } from '@apiida/controlplane-api-helper';

const user = defineStore({
  id: 'user',
  state: () => ({
    users: [] as User[],
    images: [] as { userId: number; image: string }[],
    isLoginSaml: false as boolean,
  }),
  actions: {
    getUserByDI(ouserId: number): User | undefined {
      return this.users.find((ouserInArray: User) => Number(ouserInArray.id) === Number(ouserId));
    },
    getImage(userId: number): string | undefined {
      const userImage: { userId: number; image: string } | undefined = this.images.find(
        (aI: { userId: number; image: string }) => Number(aI.userId) === Number(userId),
      );
      if (userImage) {
        return userImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(userId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { userId: number; image: string }) => {
        if (Number(aI.userId) === Number(userId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ userId, image });
      }
    },
    deleteImage(userId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { userId: number; image: string }) => Number(aI.userId) === Number(userId),
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default user;
