<template>
  <BaseBadge v-if="agentStatus === AgentStatus.NOT_CONFIGURED">No Agent</BaseBadge>

  <WarningBadge
    v-if="
      agentStatus === AgentStatus.CONNECTED && gatewayInfo?.status !== GatewayInfoStatus.CONNECTED
    "
    icon="status-warning"
  >
    Communication Error
  </WarningBadge>

  <SuccessBadge
    v-if="
      agentStatus === AgentStatus.CONNECTED && gatewayInfo?.status === GatewayInfoStatus.CONNECTED
    "
    icon="status-success"
  >
    Connected
  </SuccessBadge>

  <DangerBadge v-if="agentStatus === AgentStatus.NOT_CONNECTED" icon="status-warning">
    Agent Offline
  </DangerBadge>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { BaseBadge, DangerBadge, SuccessBadge, WarningBadge } from '@apiida/vue-components';
import { GatewayInfoStatus } from '@/types/enums/GatewayInfoStatus';
import GatewayInfo from '../../types/agent/GatewayInfo';
import { AgentStatus } from '@/types/enums/AgentStatus';

export default defineComponent({
  components: {
    DangerBadge,
    SuccessBadge,
    BaseBadge,
    WarningBadge,
  },

  props: {
    agentStatus: {
      type: String,
      default: AgentStatus.NOT_CONFIGURED,
    },
    gatewayInfo: {
      type: Object as PropType<GatewayInfo>,
      default: () => {},
    },
    withText: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const gatewayInfoText = computed(() => props.gatewayInfo?.errors?.join());

    return {
      AgentStatus,
      GatewayInfoStatus,
      gatewayInfoText,
    };
  },
});
</script>
