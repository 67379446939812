import './index.css';
import '@boomi/exosphere/dist/styles.css';
import '@boomi/exosphere/dist/exo-component-theme.css';
import '@boomi/exosphere';
import { createApp } from 'vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import Notifications from 'notiwind';
import { createPinia } from 'pinia';
// Import all components from the library
import * as Components from '@apiida/vue-components';
import App from './App.vue';
import router from './routers/Router';
import ObsidianPageHeader from './components/app/ObsidianPageHeader.vue';
import config from './config';

// Others
const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
// @ts-ignore
app.use(Notifications);

// Loader
app.component('BounceLoader', BounceLoader);
app.component('BeatLoader', BeatLoader);
app.component('PacmanLoader', PacmanLoader);
// PageHeader
app.component('ObsidianPageHeader', ObsidianPageHeader);
// Vue Components
Object.entries(Components).forEach(([name, component]) => {
  if (typeof component === 'object') {
    app.component(name, component);
  }
});

console.log(`Update the status of the agents every ${+config.reloadAgentStatus / 1000}s`);

app.mount('#app');
