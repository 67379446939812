import { defineStore } from 'pinia';
import RuleSet from '../types/configuration/RuleSet';

const ruleSetStore = defineStore({
  id: 'ruleSet',
  state: () => ({
    ruleSets: [] as RuleSet[],
  }),
  actions: {
    getRuleSetByDI(ruleSetId: number): RuleSet | undefined {
      return this.ruleSets.find(
        (ruleSetInArray: RuleSet) => Number(ruleSetInArray.id) === Number(ruleSetId),
      );
    },
  },
});

export default ruleSetStore;
