import { defineStore } from 'pinia';
import { Organization } from '@apiida/controlplane-api-helper';

const organizationStore = defineStore({
  id: 'organization',
  state: () => ({
    organizations: [] as Organization[],
    images: [] as { organizationId: number; image: string }[],
  }),
  actions: {
    getOrganizationByDI(organizationId: number): Organization | undefined {
      return this.organizations.find(
        (organizationInArray: Organization) =>
          Number(organizationInArray.id) === Number(organizationId),
      );
    },
    getImage(organizationId: number): string | undefined {
      const organizationImage: { organizationId: number; image: string } | undefined =
        this.images.find(
          (aI: { organizationId: number; image: string }) =>
            Number(aI.organizationId) === Number(organizationId),
        );
      if (organizationImage) {
        return organizationImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(organizationId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { organizationId: number; image: string }) => {
        if (Number(aI.organizationId) === Number(organizationId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ organizationId, image });
      }
    },

    deleteImage(organizationId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { organizationId: number; image: string }) =>
          Number(aI.organizationId) === Number(organizationId),
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default organizationStore;
