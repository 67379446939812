<template>
  <DangerDialog
    v-if="aStore.showDeployErrorDialog"
    id="showDeployErrorDialog"
    button-do-text="Force Deployment"
    title="Deployment Error"
    @close-dialog="close"
    @do-it="forceDeploy"
  >
    Another API with the same technical name {{ aStore.deployApiObjct.api.name }} already exists in
    environment {{ aStore.deployApiObjct.environment.displayName }}. Do you want to force the
    deployment and overwrite the existing API?
  </DangerDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DangerDialog } from '@apiida/vue-components';
import apiStore from '../../stores/ApiStore';
import ApiVersionService from '../../services/apis/ApiVersionService';
import NotifyCenter from '../../services/NotifyCenter';
import apiDeployErrorHandler from '../../helper/ApiDeployErrorHelper';

export default defineComponent({
  components: { DangerDialog },
  setup() {
    const aStore = apiStore();

    async function forceDeploy() {
      NotifyCenter.info(
        'Please wait.',
        `The deployment of version ${aStore.deployApiObjct.version.name} has started.`,
      );
      ApiVersionService.importIntoGateways(
        aStore.deployApiObjct.api.id,
        aStore.deployApiObjct.version.name,
        aStore.deployApiObjct.environment.id,
        true,
        () => {
          NotifyCenter.entityDeployed(
            `Version ${aStore.deployApiObjct.version.name}`,
            ` Deployed in ${aStore.deployApiObjct.environment.displayName}.`,
          );
          apiStore().cleanDeployApiObjct();
        },
        async (job?: any) => {
          await apiDeployErrorHandler(
            aStore.deployApiObjct.api,
            aStore.deployApiObjct.environment,
            aStore.deployApiObjct.version.name,
            job,
          );
        },
      );
    }

    function close() {
      aStore.showDeployErrorDialog = false;
    }

    return {
      close,
      aStore,
      forceDeploy,
    };
  },
});
</script>
