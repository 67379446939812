import { RouteRecordRaw } from 'vue-router';

export default [
  /* TODO: config.enableTierAndBilling */
  /*   {
    path: '/tier',
    name: 'TierView',
    beforeEnter: [RoleGuards.isGlobalAdminOrBilling, SaaSGuard.isSaas],
    component: () => import('../views/TierView.vue'),
  }, */
] as RouteRecordRaw[];
