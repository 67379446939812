import {
  ApiClientHelper,
  ExtraParamPageable,
  Job,
  Organization,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import { ref } from 'vue';
import { SortingDirection } from '@apiida/vue-components';
import ApiProduct from '../../types/apiProducts/ApiProduct';
import ApiClient from '../ApiClient';
import apiProductStore from '../../stores/ApiProductStore';
import ApiProductDeployment from '../../types/apiProducts/ApiProductDeployment';
import mainStore from '../../stores/MainStore';
import Plan from '../../types/configuration/Plan';
import Api from '../../types/apis/Api';
import NotifyCenter from '../NotifyCenter';
import PaginationServiceFileManagement from '../PaginationServiceFileManagement';
import Environment from '../../types/environments/Environment';
import Subscription from '../../types/applications/Subscription';
import TermOfUse from '../../types/configuration/TermOfUse';

class ApiProductService extends PaginationServiceFileManagement<ApiProduct> {
  constructor() {
    super('/apiProducts', 'Api Product');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async getApiProductDeployments(
    productId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false,
  ): Promise<SimplePage<ApiProductDeployment>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${productId}/deployments`,
    ) as unknown as SimplePage<ApiProductDeployment>;
  }

  async getSubscribableEnvironments(
    productId: number,
    silent: boolean = false,
  ): Promise<SimplePage<Environment>> {
    const pageable = ref<Pageable>({
      page: 1,
      size: 99,
      sort: [
        {
          field: 'displayName',
          direction: SortingDirection.ASC,
        },
      ],
    });

    return this.getAllPagination(
      pageable.value,
      [],
      [],
      silent,
      `${this.url}/${productId}/subscribableEnvironments`,
    ) as unknown as SimplePage<Environment>;
  }

  /**
   * Returns all plans that are enabled for this API product.
   * @param productId
   * @param pageable
   * @param header
   * @param extraParams
   * @param silent
   */
  async getPlans(
    productId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false,
  ): Promise<SimplePage<Plan>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${productId}/plans`,
    ) as unknown as SimplePage<Plan>;
  }

  /**
   * Returns the organizations that have access to the API product. This is important when visibility is restricted.
   * @param productId
   */
  async getOrganizations(productId: number): Promise<Organization[]> {
    const response = await ApiClient.get<Organization[]>(`${this.url}/${productId}/organizations`);
    return response?.data ?? [];
  }

  /**
   * Get all organizations (name and id only) that have been granted access to this API product. Only applies if API product visibility is RESTRICTED.
   * @param productId
   */
  async getAvailableOrganizations(productId: number): Promise<Organization[]> {
    const response = await ApiClient.get<Organization[]>(
      `${this.url}/${productId}/organizations/available`,
    );
    return response?.data ?? [];
  }

  async deleteOrganizationInApiProduct(
    productId: number,
    organizationId: number,
  ): Promise<boolean> {
    const response = await ApiClient.delete(
      `${this.url}/${productId}/organizations/${organizationId}`,
    );
    return ApiClientHelper.isSuccess(response);
  }

  // region Term of Use

  async getTermsOfUse(id: number): Promise<TermOfUse[]> {
    const response = await ApiClient.get<TermOfUse[]>(`${this.url}/${id}/termsOfUse`);
    return response?.data ?? [];
  }

  async getAvailableTermsOfUse(id: number): Promise<TermOfUse[]> {
    const response = await ApiClient.get<TermOfUse[]>(`${this.url}/${id}/termsOfUse/available`);
    return response?.data ?? [];
  }

  async addTermOfUseToApiProduct(termOfUseId: number, id: number): Promise<boolean> {
    const response = await ApiClient.put(`${this.url}/${id}/termsOfUse/${termOfUseId}`);
    return ApiClientHelper.isSuccess(response);
  }

  async removeTermOfUseToApiProduct(termOfUseId: number, id: number): Promise<boolean> {
    const response = await ApiClient.delete(`${this.url}/${id}/termsOfUse/${termOfUseId}`);
    return ApiClientHelper.isSuccess(response);
  }

  // endregion

  async getSubscriptions(
    productId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false,
  ): Promise<SimplePage<Subscription>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${productId}/subscriptions`,
    ) as unknown as SimplePage<Subscription>;
  }

  async getAllApisInProduct(
    productId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false,
  ): Promise<SimplePage<Api>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${productId}/apis`,
    ) as unknown as SimplePage<Api>;
  }

  async addPlanToApiProduct(planId: number, productId: number): Promise<boolean> {
    const response = await ApiClient.put(`${this.url}/${productId}/plans/${planId}`);
    return ApiClientHelper.isSuccess(response);
  }

  async addApiToApiProduct(planId: number, productId: number): Promise<boolean> {
    const response = await ApiClient.put(`${this.url}/${productId}/apis/${planId}`);
    return ApiClientHelper.isSuccess(response);
  }

  async addOrgaToApiProduct(productId: number, orgaId: number): Promise<boolean> {
    const response = await ApiClient.put(`${this.url}/${productId}/organizations/${orgaId}`);
    return ApiClientHelper.isSuccess(response);
  }

  async removePlanFromApiProduct(
    planId: number,
    productId: number,
    successCallback: () => void,
    failureCallback: () => void,
  ): Promise<void> {
    const response = await ApiClient.delete<Job>(`${this.url}/${productId}/plans/${planId}`);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.entityRemovedFrom('API product', 'plan');
      successCallback();
    } else {
      failureCallback();
      NotifyCenter.entityRemovedFromFailed('API product', 'plan');
    }
  }

  async removeApiFromApiProduct(
    planId: number,
    productId: number,
    successCallback: () => void,
    failureCallback: () => void,
  ): Promise<void> {
    const response = await ApiClient.delete<Job>(`${this.url}/${productId}/apis/${planId}`);

    if (ApiClientHelper.isSuccess(response)) {
      NotifyCenter.entityRemovedFrom('api', 'API product');
      successCallback();
    } else {
      failureCallback();
      NotifyCenter.entityRemovedFromFailed('api', 'API product');
    }
  }

  storeFill(entitys: ApiProduct[]): void {
    this.getStore().apiProducts = entitys;
  }

  storeGetAllEntitys(): ApiProduct[] {
    return this.getStore().apiProducts;
  }

  getStore(): any {
    return apiProductStore();
  }
}

export default new ApiProductService();
