import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/environments',
    component: () => import('../views/EmptyPage.vue'),
    beforeEnter: async (to, from, next) => {
      if (RoleGuards.isBillingContact()) {
        next('/welcome');
      }
      next();
    },
    meta: { isEntity: true, BreadcrumbName: 'Environments' },
    props: (route) => ({
      ...route.params,
      ...{ environmentId: Number(route.params.environmentId) },
    }),
    children: [
      {
        path: '',
        name: 'Environments',
        component: () => import('../views/environments/EnvironmentList.vue'),
      },
      {
        path: ':environmentId',
        name: 'Environment',
        component: () => import('../views/environments/EnvironmentRouterView.vue'),
        children: [
          {
            path: '',
            name: 'EnvironmentOverview',
            component: () => import('../views/environments/EnvironmentOverview.vue'),
          },
          {
            path: 'deployments',
            name: 'EnvironmentDeployments',
            component: () => import('../views/environments/EnvironmentDeployments.vue'),
          },
          {
            path: 'accessManagement',
            name: 'EnvironmentAccessManagement',
            meta: { BreadcrumbName: 'Access Management' },
            component: () =>
              import(
                '../views/environments/accessManagement/EnvironmentAccessManagementRouterView.vue'
              ),
            beforeEnter: RoleGuards.isGlobalAdmin,
            children: [
              {
                name: 'redirectToEnvironmentAccessManagementSubscribe',
                path: '',
                redirect: (to) => ({
                  name: 'EnvironmentAccessManagementSubscribe',
                  params: { environmentId: to.params.environmentId },
                }),
              },
              {
                path: 'subscribe',
                name: 'EnvironmentAccessManagementSubscribe',
                component: () =>
                  import(
                    '../views/environments/accessManagement/EnvironmentAccessManagementSubscribe.vue'
                  ),
              },
              {
                path: 'publish',
                name: 'EnvironmentAccessManagementPublish',
                component: () =>
                  import(
                    '../views/environments/accessManagement/EnvironmentAccessManagementPublish.vue'
                  ),
              },
            ],
          },

          {
            path: 'settings',
            name: 'EnvironmentSettings',
            component: () => import('../views/environments/EnvironmentSettings.vue'),
            beforeEnter: RoleGuards.isGlobalAdmin,
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
