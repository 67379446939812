<template>
  <PageHeader
    :title="title"
    :show-search="showSearch"
    @update-filter="(filter: string) => emit('updateFilter', filter)"
  >
    <template #Breadcrumbs>
      <div class="flex">
        <BreadcrumbList />
      </div>
    </template>

    <template #buttons>
      <slot name="buttons" />
    </template>

    <template #tabs>
      <slot name="tabs" />
    </template>
  </PageHeader>
</template>

<script setup lang="ts">
import { PageHeader } from '@apiida/vue-components';
import BreadcrumbList from './BreadcrumbList.vue';

defineProps({
  title: {
    type: String,
    default: '',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  updateFilter: [filter: string];
}>();
</script>
