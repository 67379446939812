import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/apiProducts',
    component: () => import('../views/EmptyPage.vue'),
    meta: { isEntity: true, BreadcrumbName: 'API Products' },
    beforeEnter: async (to, from, next) => {
      if (RoleGuards.isBillingContact()) {
        next('/welcome');
      }
      next();
    },
    props: (route) => ({
      ...route.params,
      ...{ apiProductId: Number(route.params.apiProductId) },
    }),
    children: [
      {
        path: '',
        name: 'ApiProducts',
        component: () => import('../views/apiProducts/ApiProductList.vue'),
      },
      {
        path: ':apiProductId',
        name: 'ApiProduct',
        component: () => import('../views/apiProducts/ApiProductDetails.vue'),
        children: [
          {
            path: '',
            name: 'ApiProductOverview',
            component: () => import('../views/apiProducts/ApiProductOverview.vue'),
          },
          {
            path: 'plans',
            name: 'ApiProductPlans',
            component: () => import('../views/apiProducts/ApiProductPlans.vue'),
          },
          {
            path: 'assignedApis',
            name: 'ApiProductAssignedApis',
            component: () => import('../views/apiProducts/ApiProductAssignedApis.vue'),
          },
          {
            path: 'subscriptions',
            name: 'ApiProductSubscriptions',
            component: () => import('../views/apiProducts/ApiProductSubscriptions.vue'),
          },
          {
            path: 'visibility',
            name: 'ApiProductVisibility',
            component: () => import('../views/apiProducts/ApiProductVisibility.vue'),
          },
          {
            path: 'settings',
            name: 'ApiProductSettings',
            component: () => import('../views/apiProducts/ApiProductSettings.vue'),
          },
          {
            path: 'termsOfUse',
            name: 'ApiProductTermsOfUse',
            component: () => import('../views/apiProducts/ApiProductTermsOfUse.vue'),
          },
          {
            path: 'deployments',
            name: 'ApiProductDeployments',
            component: () => import('../views/apiProducts/ApiProductDeployments.vue'),
          },
          {
            path: 'documentation',
            name: 'ApiProductDocumentation',
            props: true,
            component: () => import('../views/apiProducts/documentation/ApiProductDocList.vue'),
            children: [
              {
                path: '',
                name: 'ApiProductDocRootPage',
                component: () => import('../components/docPage/DocRootPage.vue'),
              },
              {
                path: 'docContent/:entityDocPageId',
                name: 'ApiProductDocPage',
                props: (route) => ({
                  ...route.params,
                  ...{ entityDocPageId: Number(route.params.entityDocPageId) },
                }),
                component: () => import('../views/documentation/DocPageView.vue'),
              },
            ] as RouteRecordRaw[],
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
