<template>
  <nav
    aria-label="Sidebar"
    :class="[lvl1BarOpen ? 'items-left pr-4' : 'items-center']"
    class="pb-6 flex flex-col"
  >
    <div
      class="menu-link flex items-center p-2 rounded-lg text-indigo-200 hover:bg-primary hover:text-secondary hover:cursor-pointer"
      @click="showTutorial = true"
    >
      <BaseIcon name="Help" label="Open Tutorial" icon-class="text-base" />
      <span v-if="lvl1BarOpen" class="pl-2">Tutorial</span>
    </div>
  </nav>

  <span v-if="tutorialData && (showTutorial || store.getFirstLoginFlag)">
    <Vue3Tutorial
      v-if="mode === TutorialMode.DESKTOP"
      class="max-lg:hidden vue3-tutorial_desktop"
      :tutorial="tutorialData"
      :open="showTutorial || store.getFirstLoginFlag"
      @stop="closeDialog"
      @start="adjustTutorialHtml"
      @change-step="adjustTutorialHtml"
    />

    <Vue3Tutorial
      v-else
      class="lg:hidden vue3-tutorial_mobile"
      :tutorial="tutorialData"
      :open="showTutorial || store.getFirstLoginFlag"
      @stop="closeDialog"
      @start="adjustTutorialHtml"
      @change-step="adjustTutorialHtml"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue';
// https://github.com/restimel/vue3-tutorial
import Vue3Tutorial, { StepDescription, Tutorial } from 'vue3-tutorial';
import BoomiLogo from '../../assets/Boomi_logo.svg';
import BoomiLogoDarkmode from '../../assets/Boomi_logo_reversed.svg';
import mainStore from '../../stores/MainStore';
import RoleGuards from '../../routers/guards/RoleGuards';
import SaaSGuard from '../../routers/guards/SaaSGuard';
import { TutorialMode } from '@/types/enums/TutorialMode';
import { colorSchemeQuery, handleColorSchemeChange, isDarkMode } from '@/helper/DarkModeHelper';
import { BaseIcon } from '@apiida/vue-components';

export default defineComponent({
  components: { Vue3Tutorial, BaseIcon },
  props: {
    lvl1BarOpen: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String as PropType<TutorialMode>,
      required: true,
    },
  },
  setup(props) {
    const store = mainStore();
    const showTutorial = ref(false);
    const isGlobalAdmin = ref(false);
    const isBillingContact = ref(true);
    const isSaaS = ref(true);
    const isAdmin = ref(false);
    const isBasicUser = ref(false);

    const tutorialData = ref<Tutorial>();

    function getTutorialSteps(tutorialMode: TutorialMode): Tutorial | undefined {
      const baseTutorialConfig = {
        name: 'short tutorial',
        steps: [
          {
            title: 'Welcome to the Boomi API Control Plane!',
            content:
              `This quick tutorial will help you understand the basic functionality and set you up for a successful use of the Boomi API Control Plane.` +
              `\n\n` +
              `For detailed information you can always take a look at the [documentation](https://apiida.atlassian.net/wiki/spaces/AACP).`,
            options: { position: 'center' },
          },
        ],
        options: {
          scroll: false,
          position: 'right',
        },
      } as Tutorial;

      let envTitleText = 'Connect Your First Environment';
      let envContentText =
        'To get started, connect your first environment using the menu item highlighted on the left. \n\n' +
        'There you can start and "Register Environment" from which you can discover APIs, API products, Subscriptions and Plans.';

      if (isAdmin.value || isBasicUser.value) {
        envTitleText = 'Environments';
        envContentText =
          'Environments show all environments connected so far. ' +
          'There, you can see details about the connected environment and which APIs are deployed there.';
      }

      const defaultSteps = [
        {
          target: `a[data-test-id="${tutorialMode}_Environments"]`,
          title: envTitleText,
          content: envContentText,
        },
        {
          target: `a[data-test-id="${tutorialMode}_APIs"]`,
          title: 'Discover Your APIs and Other Components',
          content:
            'The APIs discovered in the environments can be found here in a complete overview with some basic information. \n\n' +
            'In the detailed view of an API, there is more information about, for example, version management, deployments, analytics, and associated API products.',
        },
        {
          target: `a[data-test-id="${tutorialMode}_API Products"]`,
          title: 'API Products',
          content:
            'An API product can contain one or more APIs. To subscribe to an API, the corresponding API product must be subscribed via an application. \n\n' +
            'In the detailed view of an API product, there is more information about, for example, visibility, deployments, plans, subscriptions and associated APIs.',
        },
        {
          target: `a[data-test-id="${tutorialMode}_Applications"]`,
          title: 'Handle Applications and Subscriptions',
          content:
            'To access an API over an API product, you must first create an application. The API keys required to access the API are associated with the API products and applications. This way, you can disable access from one application to all your APIs by simply disabling the application. \n\n' +
            "This is also where an application's subscriptions to an API are managed along with some detailed information such as Analytics.",
        },
      ] as StepDescription[];

      let tmpSteps: Tutorial = { ...baseTutorialConfig };
      tmpSteps.steps = [...tmpSteps.steps, ...defaultSteps];

      if (isBillingContact.value) {
        tmpSteps = { ...baseTutorialConfig };
      }

      if (isGlobalAdmin.value || isAdmin.value) {
        tmpSteps.steps.push({
          target: `a[data-test-id="${tutorialMode}_Governance"]`,
          title: 'Governance Dashboard',
          content:
            'The governance dashboard provides you with various metrics and graphs about your API Control Plane instance. You get information about your APIs, API Products, organizations, rule sets and subscriptions.',
        } as StepDescription);

        tmpSteps.steps.push({
          target: `a[data-test-id="${tutorialMode}_Configuration"]`,
          title: 'Configure Global Settings for the API Control Plane',
          content: `The configurations handle the global settings. There, users can be managed with their roles and assigned to organizations. In addition, the authentication can be configured. \n\nThe approval settings for subscriptions and the plans available for assignment are also managed globally.`,
        } as StepDescription);
      }

      // if (isBillingContact.value) {
      //   tmpSteps.steps.push({
      //     target: `a[data-test-id="${tutorialMode}_Configuration"]`,
      //     title: 'Configure Billing and Tier Information for the API Control Plane',
      //     content: `All information about billing and the selected tier is also managed and can be changed there.`,
      //   } as StepDescription);
      // }

      return tmpSteps;
    }

    function getHtmlLogoElement(id: string): HTMLImageElement {
      const elem: HTMLImageElement = document.createElement('img');
      elem.id = id;
      if (isDarkMode.value) {
        elem.src = BoomiLogoDarkmode;
      } else {
        elem.src = BoomiLogo;
      }

      elem.classList.add('pb-2');
      elem.classList.add('mb-4');
      elem.classList.add('w-28');

      return elem;
    }

    function adjustTutorialHtml() {
      setTimeout(() => {
        // Change Steptext to Step x of y
        const stepDiv = document.querySelector(
          '.vue3-tutorial__step__header__status',
        ) as HTMLDivElement;
        let newStepText = stepDiv.innerText;
        newStepText = newStepText.charAt(0).toUpperCase() + newStepText.slice(1);
        newStepText = newStepText.replace('/', 'of');
        stepDiv.innerText = newStepText;

        // Open links in another tab.
        const links = document
          .querySelector('.vue3-tutorial__step__content')
          ?.getElementsByTagName('a');
        if (links)
          for (let i = 0; i < links.length; i += 1) {
            links[i].setAttribute('target', '_blank');
            links[i].setAttribute('rel', 'noopener');
          }

        // Add boomi Logo to Header
        const imgIdDesktop = 'vue3-tutorial__step__header-aiida-logo_desktop';
        if (document.getElementById(imgIdDesktop) === null) {
          const header = document.querySelector(
            '.vue3-tutorial_desktop > .vue3-tutorial__window > .vue3-tutorial__step > .vue3-tutorial__step__header',
          );
          header?.parentNode?.insertBefore(getHtmlLogoElement(imgIdDesktop), header);
        }
        const imgIdMobile = 'vue3-tutorial__step__header-aiida-logo_mobile';
        if (document.getElementById(imgIdMobile) === null) {
          const header = document.querySelector(
            '.vue3-tutorial_mobile > .vue3-tutorial__window > .vue3-tutorial__step > .vue3-tutorial__step__header',
          );
          header?.parentNode?.insertBefore(getHtmlLogoElement(imgIdMobile), header);
        }
      }, 1);
    }

    function closeDialog() {
      showTutorial.value = false;
      store.setFirstLoginFlag(false);
    }

    onMounted(async () => {
      isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
      isBillingContact.value = RoleGuards.isBillingContact();
      isSaaS.value = await SaaSGuard.isSaas();
      isAdmin.value = RoleGuards.isAdmin();
      isBasicUser.value = RoleGuards.isUser();
      tutorialData.value = getTutorialSteps(props.mode);

      colorSchemeQuery.value = window.matchMedia('(prefers-color-scheme: dark)');
      colorSchemeQuery.value.addListener(handleColorSchemeChange);
    });

    onUnmounted(() => {
      if (colorSchemeQuery.value) {
        colorSchemeQuery.value.removeListener(handleColorSchemeChange);
      }
    });

    return {
      tutorialData,
      adjustTutorialHtml,
      closeDialog,
      store,
      showTutorial,
    };
  },
  computed: {
    TutorialMode() {
      return TutorialMode;
    },
  },
});
</script>
<style></style>
