import { defineStore } from 'pinia';
import Webhook from '../types/configuration/advancedSettings/webhooks/Webhook';

const webhookStore = defineStore({
  id: 'webhook',
  state: () => ({
    webhooks: [] as Webhook[],
  }),
  getters: {
    getWebhocks(): Webhook[] {
      return this.webhooks;
    },
  },
  actions: {
    getWebhockByDI(webhookId: number): Webhook | undefined {
      return this.webhooks.find(
        (webhookInArray: Webhook) => Number(webhookInArray.id) === Number(webhookId),
      );
    },
  },
});

export default webhookStore;
