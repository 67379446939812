<template>
  <BaseDialog v-if="saasInfo" @close-dialog="$emit('close-dialog')">
    <div class="flex flex-col">
      <h2 class="flex pb-6 justify-center text-xl font-extrabold text-primary sm:text-2xl">
        Your Tier has been downgraded!
      </h2>
      <p class="pb-2 text-md text-gray-500">
        You have been downgraded to tier
        <strong>{{
          `${
            saasInfo.tierInfo.tier.charAt(0).toUpperCase() +
            saasInfo.tierInfo.tier.slice(1).toLowerCase()
          }`
        }}</strong
        >.
      </p>
      <p class="pb-2 text-md text-gray-500">
        Only a certain number of active APIs, Applications and Developer Portals can be used.
      </p>
      <p class="pb-2 text-md text-gray-500">
        Everything above the corresponding limit has been deactivated.
      </p>
      <p class="pb-2 text-md text-gray-500">
        You can adjust the selection at any time by setting an active entity to inactive in its
        settings and enabling another one instead.
      </p>
      <p class="pb-2 text-md text-gray-500">
        Feel free to contact us with any questions, or if you are interested in upgrading to another
        tier.
      </p>

      <div class="pt-4 pb-2">
        <PrimaryButton id="primaryBtn_contactSupport" @click="$emit('support')"
          >Contact Support
        </PrimaryButton>
      </div>
    </div>
  </BaseDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { BaseDialog } from '@apiida/vue-components';
import SaaSInfo from '../../types/app/SaaSInfo';

export default defineComponent({
  components: { BaseDialog },
  props: {
    saasInfo: {
      type: Object as PropType<SaaSInfo>,
      required: true,
    },
  },
  emits: ['close-dialog', 'support'],
});
</script>
