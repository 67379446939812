import { defineStore } from 'pinia';
import Plan from '../types/configuration/Plan';

const appStore = defineStore({
  id: 'plan',
  state: () => ({
    plans: [] as Plan[],
  }),
  getters: {
    getPlans(): Plan[] {
      return this.plans;
    },
  },
  actions: {
    getPlanByDI(planId: number): Plan | undefined {
      return this.plans.find((planInArray: Plan) => Number(planInArray.id) === Number(planId));
    },
  },
});

export default appStore;
