import { defineStore } from 'pinia';
import GitCredential from '../types/configuration/GitCredential';

const gitCredential = defineStore({
  id: 'gitCredential',
  state: () => ({
    gitCredentials: [] as GitCredential[],
  }),
  actions: {
    getGitCredentialByDI(gitCredentialId: number): GitCredential | undefined {
      return this.gitCredentials.find(
        (gitCredentialInArray: GitCredential) =>
          Number(gitCredentialInArray.id) === Number(gitCredentialId),
      );
    },
  },
});

export default gitCredential;
