import {
  ApiClientHelper,
  ExtraParamPageable,
  JobRunnerService,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import ApiVersion from '../../types/apis/ApiVersion';
import ApiClient from '../ApiClient';
import OpenProxyGitRepoAnalysis from '../../types/apis/openProxySpec/OpenProxyGitRepoAnalysis';
import NotifyCenter from '../NotifyCenter';
import OpenProxyGitVersion from '../../types/apis/openProxySpec/OpenProxyGitVersion';
import ApiService from './ApiService';

class ApiVersionService {
  jobRunnerService = new JobRunnerService(ApiClient);

  async getAllPagination(
    productId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false,
  ): Promise<SimplePage<ApiVersion>> {
    return ApiService.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `/apis/${productId}/versions`,
    ) as unknown as SimplePage<ApiVersion>;
  }

  async get(apiId: number, name: string): Promise<ApiVersion> {
    const response = await ApiClient.get<ApiVersion>(`/apis/${apiId}/versions/${name}`);

    if (response === null || response.data === null) {
      throw new Error('Version not found!');
    }

    return response.data;
  }

  async current(apiId: number): Promise<ApiVersion> {
    const response = await ApiClient.get<ApiVersion>(`/apis/${apiId}/versions/current`);

    if (response === null || response.data === null) {
      throw new Error('Version not found!');
    }

    return response.data;
  }

  async create(apiId: number, data: ApiVersion): Promise<boolean> {
    const response = await ApiClient.post(`/apis/${apiId}/versions`, data);
    return ApiClientHelper.isSuccess(response);
  }

  async update(apiId: number, name: string, data: ApiVersion): Promise<boolean> {
    const response = await ApiClient.put(`/apis/${apiId}/versions/${name}`, data);
    return ApiClientHelper.isSuccess(response);
  }

  async delete(apiId: number, name: string): Promise<boolean> {
    const response = await ApiClient.delete(`/apis/${apiId}/versions/${name}`);
    return ApiClientHelper.isSuccess(response);
  }

  async provisionToProduction(apiId: number, name: string): Promise<boolean> {
    const response = await ApiClient.post(`/apis/${apiId}/versions/${name}/provisionToProduction`);
    return ApiClientHelper.isSuccess(response);
  }

  async scanGitForTags(apiId: number): Promise<ApiVersion[] | undefined> {
    NotifyCenter.info('Scan started.', 'The specified repository is scanned for tags.');
    const response = await ApiClient.post<ApiVersion[]>(`/apis/${apiId}/scanGitForTags`);

    const result = response?.data ?? undefined;

    if (result?.length !== undefined) {
      if (result.length === 0) {
        NotifyCenter.success('Scan completed.', 'No new tags were found in the repository.');
      }

      if (result.length > 0) {
        let bodyTxt = 'The following tags were found: ';
        result.forEach((version: ApiVersion) => {
          bodyTxt += `${version.displayName} `;
        });
        NotifyCenter.success(`Scan for tags in Git repository done.`, `${bodyTxt}`);
      }
    }

    return result;
  }

  async scanGitForTagsOPS(apiId: number): Promise<OpenProxyGitRepoAnalysis> {
    NotifyCenter.info('Scan started.', 'The specified repository is scanned for tags.');
    const response = await ApiClient.post<OpenProxyGitRepoAnalysis>(
      `/apis/${apiId}/scanGitForOpenProxySpecs`,
    );

    if (response === null || response.data === null) {
      throw new Error('scan failed');
    }

    if (response.data.versions.length === 0) {
      NotifyCenter.success('Scan completed.', 'No new tags were found in the repository.');
    }

    if (response.data.versions.length > 0) {
      let bodyTxt = 'The following tags were found: ';
      response.data.versions.forEach((version: OpenProxyGitVersion) => {
        bodyTxt += `${version.versionName} `;
      });
      NotifyCenter.success(`Scan for tags in repository done.`, `${bodyTxt}`);
    }

    return response.data;
  }

  async exportFromGateway(
    apiId: number,
    version: string,
    environment: number | undefined,
    successCallback: null | (() => void) = null,
    failureCallback: null | (() => void) = null,
  ) {
    const response = await ApiClient.post(`/apis/${apiId}/exportFromGateway/async`, {
      environment,
      version,
    });
    // @ts-ignore
    const jobId = response?.data?.id ?? null;

    this.jobRunnerService.waitForJob(
      jobId,
      async () => {
        if (successCallback != null) successCallback();
      },
      () => {
        if (failureCallback != null) failureCallback();
      },
    );
  }

  async importIntoGateways(
    apiId: number,
    name: string,
    environment: number | undefined,
    overrideOnConflict: boolean = false,
    successCallback: null | (() => void) = null,
    failureCallback: null | ((job?: any) => void) = null,
  ) {
    let body = {};
    if (environment) {
      body = { environment, overrideOnConflict };
    } else {
      body = { overrideOnConflict };
    }

    const response = await ApiClient.post(
      `/apis/${apiId}/versions/${name}/importIntoGateways/async`,
      body,
    );

    // @ts-ignore
    const jobId = response?.data?.id ?? null;

    if (jobId === null)
      if (failureCallback) {
        failureCallback();
      }

    this.jobRunnerService.waitForJob(
      jobId,
      async () => {
        if (successCallback != null) {
          successCallback();
        }
      },
      (job?: any) => {
        if (failureCallback != null) failureCallback(job);
      },
    );
  }
}

export default new ApiVersionService();
