import { defineStore } from 'pinia';
import DevPortal from '../types/configuration/DevPortal';

const devPortalStore = defineStore({
  id: 'devPortal',
  state: () => ({
    devPortals: [] as DevPortal[],
    images: [] as { devPortalId: number; image: string }[],
  }),
  actions: {
    getDevPortalByDI(devPortalId: number): DevPortal | undefined {
      return this.devPortals.find(
        (devPortalInArray: DevPortal) => Number(devPortalInArray.id) === Number(devPortalId),
      );
    },
    getImage(devPortalId: number): string | undefined {
      const devPortalImage: { devPortalId: number; image: string } | undefined = this.images.find(
        (dI: { devPortalId: number; image: string }) =>
          Number(dI.devPortalId) === Number(devPortalId),
      );
      if (devPortalImage) {
        return devPortalImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(devPortalId: number, image: string): void {
      let storeIt = true;
      this.images.map((dI: { devPortalId: number; image: string }) => {
        if (Number(dI.devPortalId) === Number(devPortalId)) {
          dI.image = image;
          storeIt = false;
        }
        return dI;
      });
      if (storeIt) {
        this.images.push({ devPortalId, image });
      }
    },
    deleteImage(devPortalId: number): void {
      const indexOfObject = this.images.findIndex(
        (dI: { devPortalId: number; image: string }) =>
          Number(dI.devPortalId) === Number(devPortalId),
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default devPortalStore;
