import axios, { AxiosInstance } from 'axios';
import { ApiClient } from '@apiida/controlplane-api-helper';
import config from '../config';
import { insertTenantId } from '@/helper/TenantHelper';
import NotificationCenter from './NotifyCenter';

function getBackendUrl(): string {
  const backenUrl = insertTenantId(`${config.backendUrl}/`);

  if (process.env.NODE_ENV !== 'test') {
    console.log('Backend URL', backenUrl);
  }

  return backenUrl;
}

const apiClient: AxiosInstance = axios.create({
  baseURL: getBackendUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  validateStatus(status: number) {
    return status < 500; // Resolve only if the status code is less than 500
  },
});

// Set the AUTH token for any request
apiClient.interceptors.request.use((axiosConfig) => {
  const token = localStorage.getItem('accessToken');

  if (axiosConfig?.headers && token) {
    axiosConfig.headers.Authorization = token;
  }

  return axiosConfig;
});

// @ts-ignore
export default new ApiClient(apiClient, NotificationCenter);
