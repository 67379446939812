import {
  Organization,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import organizationStore from '../../stores/OrganizationStore';
import PaginationService from '../PaginationService';
import Environment from '../../types/environments/Environment';

class OrganizationService extends PaginationService<Organization> {
  constructor() {
    super('/organizations', 'Organization');
  }

  storeFill(entitys: Organization[]): void {
    this.getStore().organizations = entitys;
  }

  storeGetAllEntitys(): Organization[] {
    return this.getStore().organizations;
  }

  getStore(): any {
    return organizationStore();
  }

  async getAllAvailablePublishEnvironments(
    pageable: Pageable,
    organizationId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Environment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/organizations/${organizationId}/publishEnvironments`,
    ) as unknown as SimplePage<Environment>;
  }

  async getAllSubscribtionEnvironments(
    pageable: Pageable,
    organizationId: number,
    header: Array<TableHeaderPageable | string> = [],
  ): Promise<SimplePage<Environment>> {
    return this.getAllPagination(
      pageable,
      header,
      [],
      false,
      `/organizations/${organizationId}/subscribeEnvironments`,
    ) as unknown as SimplePage<Environment>;
  }
}

export default new OrganizationService();
