import { defineStore } from 'pinia';
import TermOfUse from '../types/configuration/TermOfUse';

const termOfUseStore = defineStore({
  id: 'termOfUse',
  state: () => ({
    termsOfUse: [] as TermOfUse[],
  }),
  actions: {
    getTermOfUseByDI(termOfUseId: number): TermOfUse | undefined {
      return this.termsOfUse.find(
        (termOfUseInArray: TermOfUse) => Number(termOfUseInArray.id) === Number(termOfUseId),
      );
    },
  },
});

export default termOfUseStore;
