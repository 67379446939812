<template>
  <span v-if="isBillingContact === false">
    <SidebarCaption>General</SidebarCaption>
    <BaseSidebar :navigation="itemsGeneral" />
  </span>

  <span v-if="config.enableTierAndBilling">
    <span v-if="isGlobalAdminOrBilling">
      <span v-if="isNotSaas === false">
        <SidebarCaption>Billing</SidebarCaption>
        <BaseSidebar :navigation="itemsBilling" />
      </span>
    </span>
  </span>

  <span v-if="isGlobalAdmin">
    <SidebarCaption>Subscriptions</SidebarCaption>
    <BaseSidebar :navigation="itemsSubscribtion" />

    <SidebarCaption>Integrations</SidebarCaption>
    <BaseSidebar id="IntegrationsSidebar" :navigation="itemsIntegrations" />

    <SidebarCaption>Authentication</SidebarCaption>
    <BaseSidebar :navigation="authIntegrations" />

    <SidebarCaption>Advanced settings</SidebarCaption>
    <BaseSidebar :navigation="advancedSettings" />
  </span>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';
import RoleGuards from '../../routers/guards/RoleGuards';
import SaaSGuard from '../../routers/guards/SaaSGuard';
import config from '../../config';

const itemsSubscribtion = [
  { name: 'Approvals', route: 'ConfigurationApprovals', icon: 'Check' },
  { name: 'Plans', route: 'ConfigurationPlansIndex', icon: 'Check list' },
];

const itemsIntegrations = [
  { name: 'Git Credentials', route: 'ConfigurationGitCredentialsIndex', icon: 'Secure' },
];

const itemsBilling = [
  { name: 'Billing Information', route: 'ConfigurationBillingInformation', icon: 'Payment Card' },
  { name: 'Tier Management', route: 'ConfigurationTierOverview', icon: 'Stack' },
];

const authIntegrations = [
  { name: 'SAML', route: 'ConfigurationSaml', icon: 'Key' },
  { name: 'LDAP', route: 'ConfigurationLdap', icon: 'Identity providers' },
];

const advancedSettings = [
  { name: 'Custom Fields', route: 'ConfigurationCustomApplicationFields', icon: 'Table' },
  {
    name: 'Discovery Settings',
    route: 'ConfigurationDiscoverySettings',
    icon: 'hub-dataset',
  },
  { name: 'Webhooks', route: 'ConfigurationWebhooksList', icon: 'Link' },
];

export default defineComponent({
  components: { BaseSidebar, SidebarCaption },
  setup() {
    const itemsGeneral = ref<
      {
        name: string;
        route: string;
        icon: string;
      }[]
    >([]);
    const isGlobalAdmin = ref(false);
    const isGlobalAdminOrAdmin = ref(false);
    const isGlobalAdminOrBilling = ref(false);
    const isBillingContact = ref(false);
    const isNotSaas = ref(false);

    onMounted(async () => {
      isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
      isGlobalAdminOrBilling.value = RoleGuards.isGlobalAdminOrBilling();
      isBillingContact.value = RoleGuards.isBillingContact();
      isGlobalAdminOrAdmin.value = RoleGuards.isAdminOrGlobalAdmin();
      isNotSaas.value = await SaaSGuard.isNotSaas();

      if (isGlobalAdmin.value) {
        itemsGeneral.value.push({
          name: 'General',
          route: 'ConfigurationGeneral',
          icon: 'List',
        });
        /*
        // TODO: This is the Customization option page for the Administration Portal (maybe for later)
        if (false) {
          // Not yet. First the Developer Portal
          itemsGeneral.value.push({
            name: 'Customization',
            route: 'ConfigurationCustomization',
            icon: PencilIcon,
          });
        }
        */
      }

      if (isBillingContact.value === false)
        itemsGeneral.value.push({
          name: 'Users',
          route: 'ConfigurationUsersIndex',
          icon: 'User',
        });

      if (isGlobalAdmin.value)
        itemsGeneral.value.push({
          name: 'Organizations',
          route: 'ConfigurationOrganizationsIndex',
          icon: 'Company',
        });
      if (isGlobalAdmin.value && isNotSaas.value)
        itemsGeneral.value.push({ name: 'SMTP', route: 'ConfigurationSMTP', icon: 'email' });
      if (isGlobalAdmin.value) {
        itemsGeneral.value.push({
          name: 'Developer Portals',
          route: 'ConfigurationDevPortalIndex',
          icon: 'Laptop',
        });
        itemsGeneral.value.push({
          name: 'Rulesets',
          route: 'ConfigurationRuleSetsIndex',
          icon: 'rows',
        });
        itemsGeneral.value.push({
          name: 'Terms Of Use',
          route: 'ConfigurationTermsOfUseIndex',
          icon: 'Document',
        });
      }
      if (isGlobalAdminOrAdmin.value)
        itemsGeneral.value.push({
          name: 'Audit Logs',
          route: 'ConfigurationAuditLogs',
          icon: 'History',
        });
    });

    return {
      isBillingContact,
      isGlobalAdminOrBilling,
      isNotSaas,
      isGlobalAdmin,
      itemsGeneral,
      itemsSubscribtion,
      itemsIntegrations,
      itemsBilling,
      authIntegrations,
      advancedSettings,
    };
  },
  computed: {
    config() {
      return config;
    },
  },
});
</script>
