<template>
  <SidebarCaption>General</SidebarCaption>
  <BaseSidebar id="IntegrationsSidebar" :navigation="navigation" />
</template>

<script lang="ts">
const navigation = [
  {
    name: 'Overview',
    route: 'ApiOverview',
    icon: 'Home',
  },
  {
    name: 'API Products',
    route: 'ApiApiProduct',
    icon: 'Types',
  },
  {
    name: 'Versions',
    route: 'ApiVersions',
    icon: 'Component',
  },
  {
    name: 'Analytics',
    route: 'ApiAnalytics',
    icon: 'Line graph',
  },
  {
    name: 'Deployments',
    route: 'ApiDeployments',
    icon: 'Publish',
  },
  {
    name: 'Documentation',
    route: 'ApiDocRootPage',
    icon: 'Document',
  },
  {
    name: 'Settings',
    route: 'ApiSettings',
    icon: 'Settings',
  },
];
import { defineComponent } from 'vue';
import { BaseSidebar, SidebarCaption } from '@apiida/vue-components';

export default defineComponent({
  components: {
    BaseSidebar,
    SidebarCaption,
  },
  setup() {
    return {
      navigation,
    };
  },
});
</script>
