import { AxiosResponse } from 'axios';
import { ApiClientHelper, BasicType } from '@apiida/controlplane-api-helper';
import PaginationService from './PaginationService';
import ApiClient from './ApiClient';

export default abstract class PaginationServiceFileManagement<
  T extends BasicType,
> extends PaginationService<T> {
  async createOrUpdate(id: number | undefined, file: File): Promise<boolean> {
    if (id === undefined || id < 0) {
      return false;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);

    const response = await this.apiClient.post(`${this.url}/${id}/files`, fd, false, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    const success = ApiClientHelper.isSuccess(response as unknown as AxiosResponse);

    if (success) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.getStore().storeOrUpdateImage(id, reader.result);
      };
      reader.readAsDataURL(file);
    }

    return success;
  }

  async deleteFile(productId: number, fileName: string, silent = false): Promise<boolean> {
    const response = await ApiClient.delete(`${this.url}/${productId}/files/${fileName}`, silent);
    return ApiClientHelper.isSuccess(response);
  }

  async getFile(
    id: number,
    fileName: string,
    forceReload: boolean,
    callback: any,
  ): Promise<boolean> {
    if (id === undefined || id < 0) {
      return false;
    }

    const response = await this.apiClient.get<Blob>(`${this.url}/${id}/files/${fileName}`, true, {
      responseType: 'blob',
    });

    const success = ApiClientHelper.isSuccess(response as unknown as AxiosResponse);

    if (success && response?.status === 200) {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(String(reader.result));

        this.getStore().storeOrUpdateImage(id, String(reader.result));
      };
      reader.readAsDataURL(response?.data);
    }

    if (success && response?.status === 204) {
      // return no Image found
      callback('');
    }

    return false;
  }

  async getFileNames(id: number | undefined): Promise<string[]> {
    const response = await ApiClient.get<string[]>(`${this.url}/${id}/files`);
    return response?.data ?? [];
  }
}
