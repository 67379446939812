<template>
  <MultiColumnLayout :hide-bar-by-small-resolution="hideBarBySmallResolution">
    <template #sidebar>
      <!-- Top-Bar with information on the API-->
      <ImageAvatar
        v-if="api"
        :title="api.displayName"
        :src="image"
        :default-avatar="getRandomRocketByID(api.id)"
        text-classes="w-40"
      >
        <template #subtitle>
          <p class="text-sm text-gray-500 w-40 overflow-hidden whitespace-nowrap text-ellipsis">
            <DeploymentIndicator :status="api.deploymentStatus" :show-deploy-status-text="true" />
          </p>
        </template>
      </ImageAvatar>

      <DeploymentSidebarBanner
        v-if="api"
        :status="api.deploymentStatus"
        :to="{
          name: 'ApiOverview',
          params: {
            apiId: apiId,
          },
        }"
      />

      <InActiveSidebarBanner
        v-if="api"
        entity="API"
        :status="api.status"
        :to="{
          name: 'ApiSettings',
          params: {
            apiId: apiId,
          },
        }"
      />

      <ApiSidebar />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { getRandomRocketByID, ImageAvatar } from '@apiida/vue-components';
import ApiService from '../../services/apis/ApiService';
import ApiSidebar from './ApiSidebar.vue';
import DeploymentSidebarBanner from '../common/DeploymentSidebarBanner.vue';
import apiStore from '../../stores/ApiStore';
import DeploymentIndicator from '../app/DeploymentIndicator.vue';
import InActiveSidebarBanner from '../common/InActiveSidebarBanner.vue';

export default defineComponent({
  components: {
    InActiveSidebarBanner,
    DeploymentIndicator,
    ApiSidebar,
    ImageAvatar,
    DeploymentSidebarBanner,
  },
  props: {
    apiId: {
      type: Number,
      required: true,
    },
    propImage: {
      type: String,
      default: '',
    },
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const image = ref('');

    const aStore = apiStore();

    async function loadImage(forceReload: boolean = false) {
      await ApiService.getImage(props.apiId, forceReload, (base64Image: string) => {
        image.value = base64Image;
      });
    }

    const api = computed(() => aStore.getAPIByDI(props.apiId));

    onMounted(async () => {
      await loadImage();
    });

    watch(
      () => props.propImage,
      (newValue) => {
        image.value = newValue;
      },
    );

    return {
      getRandomRocketByID,
      api,
      image,
      loadImage,
    };
  },
});
</script>
